<template>
  <div class="test">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="fid" label="序号" width="180"> </el-table-column>
      <el-table-column prop="filename" label="文件名" width="180">
      </el-table-column>
      <el-table-column prop="opdate" label="日期"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="downfile(scope.row)" type="text" size="small">下载</el-button>
          <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>



<script>
import apiService from "@/api/service.js";
export default {
  name: "info",
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      api: '',
    };
  },
  props: ["work_type"],
  mounted() {
    //用于接收service.js传入的值
    this.get_data()
    this.api = new apiService();
  },
  methods: {

    async get_data() {
      let api = new apiService()
      this.table = await api.get_lab()
      this.tableData = this.table.filelabs
      this.$message({
        type: "success",
        message: "获取成功",
        offset: 120,
        duration: 3000,
      });
    },

    downfile(row) {
      console.log("row=" + row.filename);
      this.$router.push("/download?file=" + row.filename);
    },

    del(row) {
      console.log("row=" + row.fid);
      this.api.lab_del(row.fid);
      this.$message({
        type: "success",
        message: "删除成功",
        offset: 120,
        duration: 3000,
      });
      this.get_data()
    },

  },
};
</script>

<style>
.test {
  height: 100%;
  background: beige;
}
</style>